import React from "react";

export default function LoaderSignal(props) {
  return (
    <div className={!props.loader ? "loader" : "loader1"}>
      <div
        className={
          !props.loader
            ? "justify-content-center jimu-primary-loading"
            : "justify-content-center1 jimu-primary-loading1"
        }
      ></div>
    </div>
  );
}
