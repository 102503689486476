var isProduction = true;
var url = "";

if (isProduction) {
  url = process.env.REACT_APP_FIREBASE_URL;
} else {
  url = process.env.REACT_APP_FIREBASE_URL_DEV;
}
//vg
const config = {
  WS_BASE_URL: `${process.env.REACT_APP_BASE_URL}`,
  FIREBASE_WS_BASE_URL: `${url}`,
  STRIPE_PK_DEV: `${process.env.STRIPE_PK_DEV}`,
  STRIPE_PK_PRODUCTION: `${process.env.STRIPE_PK_PRODUCTION}`,
  DOMAIN_NAME: `${process.env.REACT_APP_LOCAL_HOST}`,
  DEMO: false,
  FIREBASE_BASIC_AUTH_TOKEN: `${process.env.REACT_APP_API_KEY}`,
};

export default config;
