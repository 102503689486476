import axios from "axios";
import config from "../config/config";

const instance = axios.create({
  baseURL: config.WS_BASE_URL,
});

instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("idToken");
  config.headers["x-access-token"] = token ? token : "";
  config.headers.ContentType = "application/json";
  return config;
});
const firebaseInstance = axios.create({
  baseURL: config.FIREBASE_WS_BASE_URL,
  headers: {
    Authorization: `Basic ${config.FIREBASE_BASIC_AUTH_TOKEN}`,
  },
});
const firebaseInstanceAuth = axios.create({
  baseURL: config.FIREBASE_WS_BASE_URL,
});

// Request interceptor (already present)
firebaseInstanceAuth.interceptors.request.use(async (config) => {
  const tokenStr = localStorage.getItem("idToken");
  config.headers.Authorization = tokenStr ? `Bearer ${tokenStr}` : "";
  return config;
});

// Add response interceptor to handle 401 errors
firebaseInstanceAuth.interceptors.response.use(
  (response) => response, // Pass through successful responses
  (error) => {
    if (error.response && error.response.status === 401) {
      // Optionally remove the invalid token
      localStorage.removeItem("idToken");
      // Redirect to login page (adjust the path if needed)
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);


export const login = async function login(email, password) {
  try {
    const response = await firebaseInstance.post("opApp/login", {
      email: email,
      password: password,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getAllEvents = async () => {
  await firebaseInstance.get("app/get-events");
};

export const getBook = async (country) => {
  return await firebaseInstance.post(`app/get-book-events`, { country });
};
export const changeOrderStatus = async (cashOrderID, status) => {
  return await firebaseInstanceAuth.post(`opApp/change-order-status`, {
    cashOrderID,
    status,
  });
};

export const validateCashOrder = async (orderID, gateway, validatedBy) => {
  return await firebaseInstanceAuth.post(`opApp/validate-cash-order`, {
    orderID,
    gateway,
    validatedBy,
  });
};
export const getAllEvent = async (searchString) => {
  return await firebaseInstance.post(`opApp/search-events`, {
    searchString,
  });
};

export const getCashOrders = async (search) => {
  return await firebaseInstanceAuth.post(`opApp/search-cash-orders`, {
    searchString: search,
  });
};
export const getRefundsCashOrders = async (status) => {
  return await firebaseInstanceAuth.get(
    `opApp/get-cash-refunds?status=${status}`
  );
};
export const changeRefundsCashOrders = async (scanCodeID, status) => {
  return await firebaseInstanceAuth.post(`opApp/update-cash-refunds`, {
    scanCodeIDs: scanCodeID,
    status: status,
  });
};

export const getUnVerifiedOrgs = async () => {
  return await firebaseInstanceAuth.get("opApp/get-unverified-orgs");
};

export const getPaidEvents = async (startDate, endDate, paidStatus) => {
  return await firebaseInstanceAuth.post("opApp/get-paid-events", {
    start: startDate,
    end: endDate,
    paidStatus: paidStatus,
  });
};
export const GenerateInvoice = async function generateInvoice(eventID, orgID) {
  try {
    const response = await firebaseInstanceAuth.post(
      "opApp/generate-event-invoice",
      {
        eventID: eventID,
        orgID: orgID,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GenerateReceipt = async function generateReceipt(eventID, orgID) {
  try {
    const response = await firebaseInstanceAuth.post(
      "opApp/generate-event-receipt",
      {
        eventID: eventID,
        orgID: orgID,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updatePaidEvents = async (eventID, paidInvoice) => {
  return await firebaseInstanceAuth.post("opApp/update-paid-events", {
    eventID: eventID,
    paidInvoice: paidInvoice,
  });
};

export const Search = async function Search(eventID, searchString, isEmail) {
  try {
    const response = await firebaseInstanceAuth.post(
      "opApp/search-scan-codes",
      {
        eventID: eventID,
        searchString: searchString,
        isEmail: isEmail,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const Replace = async function Replace(eventID, oldEmail, newEmail) {
  try {
    const response = await firebaseInstanceAuth.post(
      "opApp/replace-scan-code-user-data",
      {
        eventID: eventID,
        oldEmail: oldEmail,
        newData: { newEmail },
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const Resend = async function Resend(scanCodeID) {
  try {
    const response = await axios.get(
      `opApp/resend-scan-code-email?scanCodeID: ${scanCodeID}`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllOrg = async () => {
  await firebaseInstanceAuth.get("/get-unverified-orgs");
};

export const VerifyOrgs = async function Verify(orgID) {
  try {
    const response = await firebaseInstanceAuth.post("opApp/verify-org-byID", {
      orgID: orgID,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const compareCashOrders = async function compareCashOrders(arr) {
  try {
    const response = await firebaseInstanceAuth.post(
      "opApp/compare-cash-orders",
      {
        orders: arr,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const changeCashOrdersStatus = async function changeCashOrdersStatus(
  arr
) {
  try {
    const response = await firebaseInstanceAuth.post(
      "opApp/change-order-status-array",
      {
        orders: arr,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const searchEvents = async function searchEvents(str) {
  try {
    const response = await firebaseInstance.post("opApp/search-events", {
      searchString: str,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const searchOrganization = async function searchOrganization(str) {
  try {
    const response = await firebaseInstanceAuth.post(
      "opApp/search-organizations",
      {
        searchString: str,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateOrganizationFees = async function updateOrganizationFees(
  orgID,
  obj
) {
  try {
    const response = await firebaseInstanceAuth.post("opApp/update-org-fees", {
      orgID: orgID,
      feesMap: obj,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateEventFees = async function updateEventFees(evtID, obj) {
  try {
    const response = await firebaseInstanceAuth.post(
      "opApp/update-event-fees",
      {
        eventID: evtID,
        feesMap: obj,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateTicketFees = async function updateTicketFees(
  eventID,
  ticketID,
  waveID,
  rawPrice,
  price,
  typedPrice,
  paymentFeesAmount,
  feesAmount,
  cashMap
) {
  try {
    const response = await firebaseInstanceAuth.post(
      "opApp/update-ticket-prices",
      {
        eventID: eventID,
        ticketID: ticketID,
        waveID: waveID,
        rawPrice: rawPrice,
        price: price,
        typedPrice,
        paymentFeesAmount,
        feesAmount,
        cashMap,
      }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const bookEventOrg = async function PromoteEventOrg(array) {
  try {
    const response = await firebaseInstanceAuth.post(
      "opApp/promote-event-org",
      array
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetOrgs = async function getOrgs() {
  try {
    const response = await firebaseInstance.get("app/get-orgs");

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const UpdatePaidEvents = async function updatePaidEvents(
  eventID,
  paidInvoice
) {
  try {
    return await firebaseInstanceAuth.post("/update-paid-events", {
      eventID: eventID,
      paidInvoice: paidInvoice,
    });
  } catch (error) {
    throw error;
  }
};

export const PromoteEvent = async function promoteEvent(eventID, isPromoted) {
  try {
    return await firebaseInstanceAuth.post("opApp/promote-event", {
      eventID: eventID,
      isPromoted: isPromoted,
    });
  } catch (error) {
    throw error;
  }
};

export const getLookUp = async function getLookUp() {
  try {
    return await firebaseInstanceAuth.get("opApp/get-lookups");
  } catch (error) {
    throw error;
  }
};
export const searchOrg = async function getSearchOrg({ search }) {
  try {
    return await firebaseInstance.get(`app/get-orgs?searchString=${search}`);
  } catch (error) {
    throw error;
  }
};
export const searchArtist = async function getSearchArtist({ search }) {
  try {
    return await firebaseInstance.get(`api/artists?searchString=${search}`);
  } catch (error) {
    throw error;
  }
};

export const getUsersList = async function getUsersList(country) {
  try {
    return await firebaseInstanceAuth.get(`opApp/get-users-list?country=${country}`);
  } catch (error) {
    throw error;
  }
};
export const sendNotifications = async function sendNotifications(data) {
  try {
    return await firebaseInstanceAuth.post(`opApp/send-notification`, data);
  } catch (error) {
    throw error;
  }
};
export const sendTopPicks = async function sendTopPicks(data) {
  try {
    return await firebaseInstanceAuth.post(`opApp/send-top-picks-email`, data);
  } catch (error) {
    throw error;
  }
};
export const sendGeneric = async function sendGeneric(data) {
  try {
    return await firebaseInstanceAuth.post(`opApp/send-generic-email`, data);
  } catch (error) {
    throw error;
  }
};
export const changeGateway = async function changeGateway(data) {
  try {
    return await firebaseInstanceAuth.post(`opApp/update-org-gateway`, data);
  } catch (error) {
    throw error;
  }
};
export const getTemplates = async () => {
  const response = await firebaseInstanceAuth.get(`opApp/whatsapp/templates`);
  return response.data;
};

export const createTemplate = async (templateData) => {
  const response = await firebaseInstanceAuth.post(`opApp/whatsapp/templates`, templateData);
  return response.data;
};

export const sendBulk = async (contendSid, phoneNumbers) => {
  const response = await firebaseInstanceAuth.post(`opApp/whatsapp/send-bulk`, {
    contendSid,
    phoneNumbers,
  });
  return response.data;
};

export const getLogs = async (from, till) => {
  const response = await firebaseInstanceAuth.get(`opApp/whatsapp/logs`, {
    params: { from, till },
  });
  return response.data;
};