import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Navbar from "./shared/Navbar";
import Sidebar from "./shared/Sidebar";
const App = ({ location }) => {
  const userInfo = useSelector((state) => state.auth);
  // // console.log(userInfo.user && userInfo.user.idToken);
  // // console.log(localStorage.getItem("idToken"));
  useEffect(() => {
    onRouteChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const onRouteChanged = () => {
    // // console.log("ROUTE CHANGED");
    // if (location.pathname === "/layout/RtlLayout") {
    //   body.classList.add("rtl");
    //   i18n.changeLanguage("ar");
    // } else {
    //   body.classList.remove("rtl");
    //   i18n.changeLanguage("en");
    // }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = [
      "/user-pages/login-1",
      "/user-pages/login-2",
      "/user-pages/register-1",
      "/user-pages/register-2",
      "/user-pages/lockscreen",
      "/error-pages/error-404",
      "/error-pages/error-500",
      "/general-pages/landing-page",
    ];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (location.pathname === fullPageLayoutRoutes[i]) {
        document
          .querySelector(".page-body-wrapper")
          .classList.add("full-page-wrapper");
        break;
      } else {
        document
          .querySelector(".page-body-wrapper")
          .classList.remove("full-page-wrapper");
      }
    }
  };

  const navbarComponent = !userInfo?.user ? null : <Navbar />;
  const sidebarComponent = !userInfo?.user ? null : <Sidebar />;
  // // console.log(userInfo);
  return (
    <div className="light container-scroller">
      {sidebarComponent}
      <div className="container-fluid page-body-wrapper">
        {navbarComponent}
        <div className="main-panel">
          <div className="content-wrapper">
            <AppRoutes isAuthenticated={userInfo.isLoggedIn} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(App);
